var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner-target-live-search-modal"},[_c('div',{on:{"click":_vm.openLiveSearchModal}},[_vm._t("default")],2),_c('b-modal',{attrs:{"title":_vm.modalTitle,"no-close-on-backdrop":"","no-close-on-esc":"","hide-header-close":"","centered":"","ok-disabled":!_vm.liveSearchModal.selectedItem},on:{"ok":function($event){return _vm.okAction(_vm.liveSearchModal.selectedItem)},"cancel":_vm.cancelAction},model:{value:(_vm.liveSearchModal.show),callback:function ($$v) {_vm.$set(_vm.liveSearchModal, "show", $$v)},expression:"liveSearchModal.show"}},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"input-icon input-icon-right"},[_vm._l((_vm.searchValue),function(value,key){return [(_vm.isShowSearchField(key))?_c('AppFormField',{key:key,staticClass:"mb-4",attrs:{"state":_vm.validateObjState('searchValue', key),"validationValue":_vm.$v.searchValue[key],"validators":['minLength'],"validationParams":[
              {
                key: 'minLength',
                params: [2]
              }
            ],"placeholder":_vm.$t(("label." + key)),"no-title":""},on:{"keyup":_vm.searchAction},model:{value:(_vm.$v.searchValue[key].$model),callback:function ($$v) {_vm.$set(_vm.$v.searchValue[key], "$model", $$v)},expression:"$v.searchValue[key].$model"}}):_vm._e()]})],2),_c('span',{staticClass:"form-text text-muted"},[_vm._v(_vm._s(_vm.$t("label.inputToSearchRecord")))])]),_c('div',{staticClass:"max-h-300px overflow-y-scroll"},[_c('b-list-group',[_vm._l((_vm.liveSearchModal.items),function(item,index){return [_c('b-list-group-item',{key:index,staticClass:"btn btn-hover-primary rounded-0",attrs:{"button":""},on:{"click":function($event){return _vm.selectItemAction(item)}}},[_vm._v(" "+_vm._s(_vm.getTargetDisplayName(item, _vm.type))+" ")])]}),(_vm.liveSearchModal.items.length < 1)?_c('b-list-group-item',[_vm._v(" "+_vm._s(("" + (_vm.$t('label.noResultsMatched'))))+" ")]):_vm._e(),(
            _vm.liveSearchModal.pagination.currentPage !=
            _vm.liveSearchModal.pagination.lastPage
          )?_c('b-list-group-item',{staticClass:"text-center font-weight-bold text-primary btn btn-hover-primary rounded-0 text-capitalize",on:{"click":_vm.loadMoreAction}},[_vm._v(" "+_vm._s(_vm.$t('label.loadMore'))+" ")]):_vm._e()],2)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }