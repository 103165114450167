<template>
  <b-card class="w-100">
    <template #header>
      <h4>{{ $t('label.details') }}</h4>
    </template>

    <section>
      <BannerForm
        ref="bannerForm"
        :value="bannerObj"
        @submit="saveChanges"
      ></BannerForm>
    </section>

    <div class="separator separator-dashed my-10"></div>

    <section>
      <b-row no-gutters class="justify-content-center">
        <b-col cols="12" md="8">
          <template v-for="(item, index) in otherInfo">
            <b-row :key="index" no-gutters class="mb-4">
              <b-col cols="12" md="3">
                <label>{{ $t(`label.${item.label}`) }}</label>
              </b-col>
              <b-col cols="12" md="9">
                {{ bannerObj[item.key] }}
              </b-col>
            </b-row>
          </template>
        </b-col>
      </b-row>
    </section>

    <section>
      <b-row no-gutters class="justify-content-center pt-8">
        <b-col cols="12" md="8">
          <b-row no-gutters class="mb-4 justify-content-end">
            <b-col cols="12" md="9">
              <b-button
                variant="light-danger"
                @click="openRequestDeleteBannerModal(id)"
                >{{ $t('label.deleteThisBanner') }}</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </section>
  </b-card>
</template>

<script>
  import BannerForm from '@/views/components/banner/BannerForm';
  import { ROUTE_NAME } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import commonMixin from '@/core/mixins/common.mixin';
  import {
    BANNER_GET_BANNER,
    BANNER_UPDATE_BANNER,
    BANNER_DELETE_BANNER,
    BANNER_INITIAL_GET_BANNER_STATE,
    BANNER_INITIAL_UPDATE_BANNER_STATE,
    BANNER_INITIAL_DELETE_BANNER_STATE
  } from '@/core/store/banner.module';

  const TYPE = {
    PRODUCTION_HOUSE_OR_FREELANCER: 'Production House & Freelancers',
    TALENTS_OR_TALENT_AGENCIES: 'Talents & Talent Agencies'
  };

  export default {
    name: 'BannerDetails',
    components: {
      BannerForm
    },
    mixins: [commonMixin],
    data: () => ({
      id: null,
      bannerObj: {},
      otherInfo: [
        {
          key: 'createdAt',
          label: 'createdAt'
        },
        {
          key: 'updatedAt',
          label: 'updatedAt'
        }
      ]
    }),
    computed: {
      bannerComplete() {
        return this.$store.state.banner.banner.complete;
      },
      updateBannerComplete() {
        return this.$store.state.banner.updateBanner.complete;
      },
      deleteBannerComplete() {
        return this.$store.state.banner.deleteBanner.complete;
      }
    },
    watch: {
      bannerComplete() {
        let response = this.$store.state.banner.banner;
        let title = i18nHelper.getMessage('label.getBanner');
        let initialStateAction = BANNER_INITIAL_GET_BANNER_STATE;
        let successAction = (response) => {
          this.getBannerSucceedAction(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      updateBannerComplete() {
        let response = this.$store.state.banner.updateBanner;
        let title = i18nHelper.getMessage('label.updateBanner');
        let initialStateAction = BANNER_INITIAL_UPDATE_BANNER_STATE;
        let successAction = () => {
          this.getBanner(this.id);
        };
        let errorAction = () => {};

        if (response.complete) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      },
      deleteBannerComplete() {
        let response = this.$store.state.banner.deleteBanner;
        let title = i18nHelper.getMessage('label.deleteBanner');
        let initialStateAction = BANNER_INITIAL_DELETE_BANNER_STATE;
        let successAction = () => {
          this.deleteBannerSucceedAction(response);
        };
        let errorAction = () => {};

        if (response.complete) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      }
    },
    created() {
      this.initBannerDetails();
    },
    methods: {
      deleteBannerSucceedAction() {
        this.$router.push({ name: ROUTE_NAME.BANNER_LIST });
      },
      openRequestDeleteBannerModal(id) {
        let title = i18nHelper.getMessage('label.deleteBanner');
        let messages = [];
        let buttons = [];

        messages.push(
          i18nHelper.getMessage('message.requestDeleteMessage', [
            i18nHelper.getMessage('label.banner').toLowerCase()
          ])
        );

        buttons.push(
          {
            text: i18nHelper.getMessage('label.cancel'),
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          },
          {
            text: i18nHelper.getMessage('label.delete'),
            variant: 'danger',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.deleteBanner(id);
            }
          }
        );

        this.openLayoutAppModalInfo(title, messages, buttons);
      },
      saveChanges(data) {
        this.updateBanner(data, this.id);
      },
      getViewFor(data) {
        if (data == 'Production House & Freelancers') {
          return TYPE.PRODUCTION_HOUSE_OR_FREELANCER;
        } else if (data == 'Talents & Talent Agencies') {
          return TYPE.TALENTS_OR_TALENT_AGENCIES;
        }
      },
      getBannerSucceedAction(response) {
        let d = response.data;

        this.bannerObj = {
          viewFor: d.viewFor,
          type: d.type,
          targetId: d.target?.id,
          targetDisplayName: this.$refs.bannerForm.getTargetDisplayName(
            d.target,
            d.type
          ),
          title: d.title,
          body: d.body,
          show: d.show,
          textBackgroundColor: d.textBgColor,
          textColor: d.textColor,
          image: d.image.mediaPath,
          sequence: d.seq,
          createdAt: d.createdAt,
          updatedAt: d.updatedAt
        };
      },
      deleteBanner(id) {
        this.$store.dispatch(BANNER_DELETE_BANNER, { id });
      },
      updateBanner(data, id) {
        this.$store.dispatch(BANNER_UPDATE_BANNER, { data, id });
      },
      getBanner(id) {
        this.$store.dispatch(BANNER_GET_BANNER, { id });
      },
      initBannerDetails() {
        let id = Number(this.$route.params.id);
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.banner'),
              route: { name: ROUTE_NAME.BANNER }
            },
            { title: i18nHelper.getMessage('label.bannerDetails') }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };
        this.id = id;
        this.initBreadCrumb(breadcrumbData);
        this.getBanner(id);
      }
    }
  };
</script>

<style></style>
