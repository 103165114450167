<template>
  <div>
    <b-row no-gutters class="justify-content-center">
      <b-col cols="12" md="8">
        <template v-for="(item, index) in formFields">
          <b-row
            no-gutters
            v-if="!(isNewForm && item.key == 'sequence')"
            :key="index"
            class="mb-8"
          >
            <b-col cols="12" md="3">
              <label v-if="item.key != 'show'">{{
                $t(`label.${item.title}`)
              }}</label>
            </b-col>
            <b-col cols="12" md="9">
              <AppFormField
                v-if="!['image', 'targetId'].includes(item.key)"
                v-model="$v.newBannerObj[item.key].$model"
                :id="`${item.key}-id`"
                :state="validateObjState('newBannerObj', item.key)"
                :validationValue="$v.newBannerObj[item.key]"
                :validators="item.validators"
                :validationParams="item.validationParams"
                :formType="item.formType"
                :type="item.type"
                :options="
                  item.key == 'type' ? getBannerTypeOptions() : item.options
                "
                :textField="item.textField"
                :accept="item.accept"
                :stacked="item.stacked"
                :size="item.size"
                :disabled="isFormFieldDisabled(item.key)"
                :class="item.class"
                :switches="item.switches"
                solid
                noTitle
              >
                <template v-slot:checkboxSlot>
                  {{ $t('label.show') }}
                </template>
              </AppFormField>
              <BannerTargetLiveSearchModal
                ref="bannerTargetLiveSearchModal"
                v-else-if="item.key == 'targetId'"
                :type="newBannerObj.type"
                :disabled="isFormFieldDisabled(item.key)"
                :getTargetDisplayName="getTargetDisplayName"
                @ok="ok"
              >
                <AppFormField
                  v-model="newBannerObj.targetDisplayName"
                  :state="validateObjState('newBannerObj', 'targetDisplayName')"
                  :validationValue="$v.newBannerObj.targetDisplayName"
                  :validators="['required']"
                  :disabled="isFormFieldDisabled(item.key)"
                  solid
                  readonly
                  no-title
                >
                </AppFormField>
              </BannerTargetLiveSearchModal>

              <div v-else-if="item.key == 'image'" class="banner-image-wrapper">
                <div class="preview-wrapper">
                  <div
                    v-if="newBannerObj.image"
                    class="rounded-lg position-relative"
                  >
                    <img :src="newBannerObj.image" class="w-100 rounded-lg" />
                    <div
                      class="preview-background-color d-flex justify-content-between align-items-center text-truncate p-5 max-w-100 text-wrap text-break"
                      :style="` background-color: ${ui.hexToRGBA(
                        newBannerObj.textBackgroundColor,
                        previewTextBackgroundOpacity
                      )}; color: ${newBannerObj.textColor}`"
                    >
                      <div>
                        {{ newBannerObj.body }}
                      </div>
                    </div>
                  </div>
                  <img
                    v-else
                    src="/media/dummy/dummy-choose-an-image.png"
                    class="w-100"
                  />
                </div>

                <AppImageCropper
                  ref="appImageCropper"
                  class="pt-4"
                  :showPreview="false"
                  :hide="isFormFieldDisabled(item.key)"
                  :value="newBannerObj.image"
                  @input="inputCroppedImage"
                ></AppImageCropper>
              </div>

              <template v-for="(wm, index) in warningMessages">
                <small
                  :key="index"
                  v-if="item.key == wm.key"
                  class="text-warning"
                  >{{
                    $t(`message.${wm.message}`, [
                      $t(`label.${wm.targetField}`).toLowerCase()
                    ])
                  }}</small
                >
              </template>
            </b-col>
          </b-row>
        </template>

        <b-row
          v-if="
            isNewForm
              ? isNewForm
              : checkAccessRight(module.BANNER, [action.BANNER_UPDATE])
          "
          no-gutters
          class="justify-content-end pt-4"
        >
          <b-col cols="12" md="9">
            <b-button
              size="lg"
              :variant="isNewForm ? 'success' : 'primary'"
              @click="submit"
              >{{
                $t(`label.${isNewForm ? 'submit' : 'saveChanges'}`)
              }}</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import BannerTargetLiveSearchModal from '@/views/components/banner/BannerTargetLiveSearchModal';
  import AppImageCropper from '@/views/components/bases/AppImageCropper';
  import commonMixin from '@/core/mixins/common.mixin';
  import { hexColor, MODULE, ACTION } from '@/core/constants';
  import { BANNER_TYPE } from '@/core/constants/enums';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import { validationMixin } from 'vuelidate';
  import { required, numeric, requiredIf } from 'vuelidate/lib/validators';

  const TYPE = {
    PRODUCTION_HOUSE_OR_FREELANCER: 'Production House & Freelancers',
    TALENTS_OR_TALENT_AGENCIES: 'Talents & Talent Agencies'
  };

  export default {
    name: 'BannerForm',
    components: {
      AppFormField,
      BannerTargetLiveSearchModal,
      AppImageCropper
    },
    mixins: [commonMixin, validationMixin],
    props: {
      isNewForm: {
        type: Boolean,
        default: false
      },
      value: {
        type: Object,
        default: () => ({})
      }
    },
    data: () => ({
      module: MODULE,
      action: ACTION,
      ui: uiHelper,
      newBannerObj: {
        viewFor: '',
        type: '',
        targetId: null,
        targetDisplayName: '',
        title: '',
        body: '',
        show: false,
        textBackgroundColor: '#000000',
        textColor: '#ffffff',
        image: null,
        sequence: null
      },
      formFields: [],
      warningMessages: [
        {
          key: 'type',
          message: 'pleaseCompleteCertainInputBeforeInput',
          targetField: 'viewBy'
        },
        {
          key: 'targetId',
          message: 'pleaseCompleteCertainInputBeforeInput',
          targetField: 'selection'
        }
      ],
      previewTextBackgroundOpacity: 0.6
    }),
    watch: {
      'newBannerObj.viewFor'(newValue, oldValue) {
        if (oldValue) {
          this.newBannerObj.type = null;
          newValue;
        }
      },
      'newBannerObj.type'(newValue, oldValue) {
        if (oldValue) {
          this.$refs.bannerTargetLiveSearchModal[0].resetLiveSearchModal();
          this.newBannerObj.targetId = null;
          this.newBannerObj.targetDisplayName = null;
          newValue;
        }
      },
      value() {
        if (this.value) {
          this.newBannerObj = {
            ...this.value
          };
          this.$nextTick(() => {
            this.$v.$reset();
          });
        }
      }
    },
    created() {
      this.initBannerForm();
    },
    methods: {
      inputCroppedImage(value) {
        this.newBannerObj.image = value;
      },
      getTargetDisplayName(item, type) {
        let newItem = null;

        if (type == BANNER_TYPE.CASTING) {
          newItem = item.title ? item.title : item.name;
        } else if (type == BANNER_TYPE.PRODUCTION_HOUSE) {
          newItem = item.name ? item.name : item.displayName;
        } else if (type == BANNER_TYPE.EMPTY_TALENT) {
          newItem = '';
        } else if (type == BANNER_TYPE.EMPTY_OTHERS) {
          newItem = '';
        } else {
          newItem = `${item.firstName} ${item.lastName}`;
        }

        return newItem;
      },
      ok(data) {
        this.newBannerObj.targetId = data.id;
        this.newBannerObj.targetDisplayName = this.getTargetDisplayName(
          data,
          this.newBannerObj.type
        );
      },
      getBannerTypeOptions() {
        let list = [];
        let obj = this.newBannerObj;

        switch (obj.viewFor) {
          case TYPE.TALENTS_OR_TALENT_AGENCIES:
            list = [
              {
                text: `--- ${i18nHelper.getMessage(`label.empty`)} ---`,
                value: BANNER_TYPE.EMPTY_TALENT
              },
              {
                text: i18nHelper.getMessage(
                  `enumBannerType.${BANNER_TYPE.PRODUCTION_HOUSE}`
                ),
                value: BANNER_TYPE.PRODUCTION_HOUSE
              },
              {
                text: i18nHelper.getMessage(
                  `enumBannerType.${BANNER_TYPE.FREELANCER}`
                ),
                value: BANNER_TYPE.FREELANCER
              },
              {
                text: i18nHelper.getMessage(
                  `enumBannerType.${BANNER_TYPE.CASTING}`
                ),
                value: BANNER_TYPE.CASTING
              }
            ];
            break;
          case TYPE.PRODUCTION_HOUSE_OR_FREELANCER:
            list = [
              {
                text: `--- ${i18nHelper.getMessage(`label.empty`)} ---`,
                value: BANNER_TYPE.EMPTY_OTHERS
              },

              {
                text: i18nHelper.getMessage(
                  `enumBannerType.${BANNER_TYPE.TALENT}`
                ),
                value: BANNER_TYPE.TALENT
              },
              {
                text: i18nHelper.getMessage(
                  `enumBannerType.${BANNER_TYPE.AGENCY}`
                ),
                value: BANNER_TYPE.AGENCY
              }
            ];
            break;
        }

        return list;
      },
      isFormFieldDisabled(key) {
        let isDisabled = false;

        if (key == 'type') {
          isDisabled = this.newBannerObj.viewFor ? false : true;
        } else if (key == 'targetId') {
          isDisabled = this.newBannerObj.type ? false : true;
        }

        if (!this.isNewForm) {
          isDisabled = !this.checkAccessRight(MODULE.BANNER, [
            ACTION.BANNER_UPDATE
          ]);
        }

        if (key == 'targetId') {
          isDisabled = [
            BANNER_TYPE.EMPTY_OTHERS,
            BANNER_TYPE.EMPTY_TALENT
          ].includes(this.newBannerObj.type);
        }
        return isDisabled;
      },
      isShow(key) {
        let show = false;

        if (key == 'type') {
          if (this.newBannerObj.viewFor) {
            show = true;
          }
        } else if (key == 'targetId') {
          if (this.newBannerObj.type) {
            show = true;
          } else {
            show = false;
          }
        } else {
          show = true;
        }

        return show;
      },
      submit() {
        let isImageEmpty = this.$refs.appImageCropper[0].checkIsImageEmpty();

        if (this.validationCheck('newBannerObj') && !isImageEmpty) {
          let obj = this.newBannerObj;
          let data = {
            ...obj
          };

          //Mutate the data to match the API
          data.show = Number(obj.show);

          //Empty the data image if the image is URL link
          if (/http|https/.test(obj.image)) {
            data.image = null;
          } else {
            data.image = uiHelper.dataURLtoFile(obj.image, 'banner-image');
          }

          this.$emit('submit', data);
        }
      },
      initialFormFields() {
        this.formFields.push(
          {
            key: 'viewFor',
            title: 'viewBy',
            formType: 'radio',
            stacked: true,
            size: 'lg',
            options: [
              {
                text: i18nHelper.getMessage(
                  'label.productionHouseOrFreelancer'
                ),
                value: TYPE.PRODUCTION_HOUSE_OR_FREELANCER
              },
              {
                text: i18nHelper.getMessage('label.talentsOrTalentAgencies'),
                value: TYPE.TALENTS_OR_TALENT_AGENCIES
              }
            ],
            validators: ['required']
          },
          {
            key: 'type',
            title: 'selection',
            formType: 'select',
            options: [],
            validators: ['required'],
            class: 'app-form-field__select-capitalize'
          },
          {
            key: 'targetId',
            title: 'search',
            solid: true
          },
          {
            key: 'sequence',
            title: 'sequence',
            validators: ['required', 'numeric']
          },
          {
            key: 'title',
            title: 'title',
            validators: ['required']
          },
          {
            key: 'body',
            title: 'body',
            formType: 'textarea'
          },
          {
            key: 'textBackgroundColor',
            title: 'textBackgroundColor',
            formType: 'colorInput',
            validators: ['required', 'hexColor']
          },
          {
            key: 'textColor',
            title: 'textColor',
            formType: 'colorInput',
            validators: ['required', 'hexColor']
          },
          {
            key: 'image',
            title: 'image'
          },
          {
            key: 'show',
            title: 'show',
            formType: 'checkbox',
            options: [{ text: 'show', value: true }],
            switches: true,
            size: 'lg',
            validators: ['required']
          }
        );
      },
      initBannerForm() {
        this.initialFormFields();
      }
    },
    validations() {
      var formObj = this.newBannerObj;
      let validations = {
        newBannerObj: {
          viewFor: {
            required
          },
          type: {
            required
          },
          targetDisplayName: {
            required: requiredIf(function () {
              return ![
                BANNER_TYPE.EMPTY_OTHERS,
                BANNER_TYPE.EMPTY_TALENT
              ].includes(formObj.type);
            })
          },
          title: {
            required
          },
          body: {},
          textBackgroundColor: {
            required,
            hexColor
          },
          textColor: {
            required,
            hexColor
          },
          show: {
            required
          }
        }
      };

      if (!this.isNewForm) {
        validations.newBannerObj.sequence = {
          required,
          numeric
        };
      }

      return validations;
    }
  };
</script>

<style lang="scss">
  .app-form-field__select-capitalize {
    select {
      text-transform: capitalize;
      option {
        text-transform: capitalize;
      }
    }
  }

  .banner-image-wrapper {
    .preview-background-color {
      position: absolute;
      z-index: 2;
      height: 30%;
      width: 100%;
      bottom: 0;
      border-radius: 0 0 0.85rem 0.85rem;
    }
  }
</style>
