<template>
  <div class="banner-target-live-search-modal">
    <!-- begin:: input for open live search modal -->
    <div @click="openLiveSearchModal">
      <slot />
    </div>
    <!-- end:: input for open live search modal -->

    <!--begin::Live Search Modal-->
    <b-modal
      v-model="liveSearchModal.show"
      :title="modalTitle"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      centered
      :ok-disabled="!liveSearchModal.selectedItem"
      @ok="okAction(liveSearchModal.selectedItem)"
      @cancel="cancelAction"
    >
      <div class="form-group">
        <div class="input-icon input-icon-right">
          <template v-for="(value, key) in searchValue">
            <AppFormField
              :key="key"
              v-if="isShowSearchField(key)"
              v-model="$v.searchValue[key].$model"
              :state="validateObjState('searchValue', key)"
              :validationValue="$v.searchValue[key]"
              :validators="['minLength']"
              :validationParams="[
                {
                  key: 'minLength',
                  params: [2]
                }
              ]"
              :placeholder="$t(`label.${key}`)"
              no-title
              class="mb-4"
              @keyup="searchAction"
            >
            </AppFormField>
          </template>
        </div>
        <span class="form-text text-muted">{{
          $t(`label.inputToSearchRecord`)
        }}</span>
      </div>

      <div class="max-h-300px overflow-y-scroll">
        <b-list-group>
          <template v-for="(item, index) in liveSearchModal.items">
            <b-list-group-item
              :key="index"
              button
              class="btn btn-hover-primary rounded-0"
              @click="selectItemAction(item)"
            >
              {{ getTargetDisplayName(item, type) }}
            </b-list-group-item>
          </template>

          <!-- begin:: No results matched -->
          <b-list-group-item v-if="liveSearchModal.items.length < 1">
            {{ `${$t('label.noResultsMatched')}` }}
          </b-list-group-item>
          <!-- end:: No results matched -->

          <!-- begin:: Increase more item -->
          <b-list-group-item
            v-if="
              liveSearchModal.pagination.currentPage !=
              liveSearchModal.pagination.lastPage
            "
            class="text-center font-weight-bold text-primary btn btn-hover-primary rounded-0 text-capitalize"
            @click="loadMoreAction"
          >
            {{ $t('label.loadMore') }}
          </b-list-group-item>
          <!-- end:: Increase more item -->
        </b-list-group>
      </div>
    </b-modal>
    <!--end::Live Search Modal-->
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import commonMixin from '@/core/mixins/common.mixin';
  import { validationMixin } from 'vuelidate';
  import { minLength } from 'vuelidate/lib/validators';
  import { i18nHelper } from '@/core/utils';
  import { BANNER_TYPE } from '@/core/constants/enums';
  import { GLOBAL } from '@/core/constants';
  import {
    BANNER_GET_TARGET_LIST,
    BANNER_INITIAL_GET_TARGET_LIST_STATE
  } from '@/core/store/banner.module';

  export default {
    name: 'BannerTargetLiveSearchModal',
    mixins: [commonMixin, validationMixin],
    components: {
      AppFormField
    },
    props: {
      type: {
        type: String,
        default: BANNER_TYPE.TALENT
      },
      disabled: {
        type: Boolean,
        default: false
      },
      getTargetDisplayName: {
        type: Function,
        default: () => ({})
      }
    },
    data: () => ({
      liveSearchModal: {
        show: false,
        title: '',
        items: [],
        selectedItem: null,
        pagination: GLOBAL.DEFAULT_TABLE_PAGINATION
      },
      searchValue: {
        firstName: '',
        lastName: '',
        name: '',
        title: ''
      },
      timer: null
    }),
    computed: {
      targetListComplete() {
        return this.$store.state.banner.targetList.complete;
      },
      modalTitle() {
        return `${i18nHelper.getMessage('label.search')} ${i18nHelper
          .getMessage(
            `enumBannerType.${this.type ? this.type : BANNER_TYPE.TALENT}`
          )
          .toLowerCase()}`;
      }
    },
    watch: {
      targetListComplete() {
        let response = this.$store.state.banner.targetList;
        let title = i18nHelper.getMessage('label.getTargetList');
        let initialStateAction = BANNER_INITIAL_GET_TARGET_LIST_STATE;
        let successAction = (response) => {
          this.targetListCompleteAction(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    methods: {
      isShowSearchField(key) {
        let isShow = true;

        if (key == 'name') {
          isShow = this.type == BANNER_TYPE.PRODUCTION_HOUSE;
        } else if (key == 'title') {
          isShow = this.type == BANNER_TYPE.CASTING;
        }

        if (['firstName', 'lastName'].includes(key)) {
          isShow = this.type != BANNER_TYPE.CASTING;
        }

        return isShow;
      },
      selectItemAction(item) {
        this.liveSearchModal.selectedItem = item;
      },
      searchAction() {
        let waitTime = 1000;
        let data = {
          type: this.type,
          ...this.searchValue,
          ...GLOBAL.DEFAULT_SEARCH_CRITERIA
        };

        if (this.validationCheck('searchValue')) {
          // Clear timer
          clearTimeout(this.timer);

          // Wait for X ms and then process the request
          this.timer = setTimeout(() => {
            this.liveSearchModal.items = [];
            this.getTargetList(data);
          }, waitTime);
        }
      },
      loadMoreAction() {
        let data = {
          type: this.type,
          ...this.searchValue,
          page: this.liveSearchModal.pagination.currentPage + 1
        };

        if (!this.disabled) {
          this.getTargetList(data);
        }
      },
      targetItem(item) {
        let newItem = null;

        if (this.type == BANNER_TYPE.CASTING) {
          newItem = item.title;
        } else {
          newItem =
            (item.displayName ? `${item.displayName} - ` : '') + item.fullName;
        }

        return newItem;
      },
      targetListCompleteAction(response) {
        this.liveSearchModal.items = [
          ...this.liveSearchModal.items,
          ...response.data
        ];
        this.liveSearchModal.pagination = response.pagination;
        this.liveSearchModal.show = true;
        this.$v.searchValue.$reset();
      },
      openLiveSearchModal() {
        let data = {
          type: this.type
        };

        if (!this.disabled) {
          this.getTargetList(data);
        }
      },
      okAction(value) {
        this.$emit('ok', value);
        this.resetLiveSearchModal();
      },
      cancelAction() {
        this.resetLiveSearchModal();
      },
      resetLiveSearchModal() {
        this.liveSearchModal = {
          show: false,
          title: '',
          items: [],
          selectedItem: null,
          pagination: GLOBAL.DEFAULT_TABLE_PAGINATION
        };

        this.searchValue = {
          firstName: '',
          lastName: '',
          name: '',
          title: ''
        };
      },
      getTargetList(data) {
        this.$store.dispatch(BANNER_GET_TARGET_LIST, { data });
      }
    },
    validations: {
      searchValue: {
        firstName: {
          minLength: minLength(2)
        },
        lastName: {
          minLength: minLength(2)
        },
        name: {
          minLength: minLength(2)
        },
        title: {
          minLength: minLength(2)
        }
      }
    }
  };
</script>

<style></style>
